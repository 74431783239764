<template>
  <v-dialog
    v-model="dialog.display"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
    no-click-animation
    scrollable
  >
    <v-card tile color="#e8eff5">
      <v-toolbar flat color="grey lighten-5" max-height="64">
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="secondary-font font-weight-medium text-h6">
            {{ dialog.title }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            text
            @click="saveWorkingTime()"
            :disabled="$v.form.$invalid"
            :loading="loading.save"
            >{{ $t("btn.save") }}</v-btn
          >
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mb-0 pb-0 pt-6" style="height: 400px">
        <v-card class="mx-auto mb-5" max-width="500">
          <v-card-text class="mb-0 pb-0">
            <v-select
              :items="services"
              :label="$t('common.fields.serviceId.title')"
              :placeholder="$t('common.fields.serviceId.placeholder')"
              v-model="form.serviceId"
              :error-messages="serviceIdErrors"
              @input="delayTouch($v.form.serviceId)"
              @blur="$v.form.serviceId.$touch"
              item-value="id"
              item-text="name"
            ></v-select>
            <v-text-field
              :label="$t('common.fields.title.title')"
              :placeholder="$t('common.fields.title.placeholder')"
              v-model="form.title"
              :error-messages="titleErrors"
              @input="delayTouch($v.form.title)"
              @blur="$v.form.title.$touch"
              autocomplete="off"
            ></v-text-field>
          </v-card-text>
          <v-card-text class="mt-0">
            <div class="font-weight-bold mb-5">
              {{ $t("working_time.fields.hour.title") }}
            </div>
            <v-divider></v-divider>
            <template v-for="(hour, day) in form.hours">
              <div
                class="d-flex align-center justify-space-between my-3"
                :key="day"
              >
                <div class="font-weight-medium">
                  {{ $t("common.days." + day) }}
                </div>
                <div class="d-flex align-center justify-self-end">
                  <v-select
                    class="number-select mr-2"
                    v-model="form.hours[day].start.hour"
                    :items="hours"
                    outlined
                    dense
                    hide-details
                    style="width: 60px"
                  ></v-select>
                  <v-select
                    class="number-select"
                    v-model="form.hours[day].start.minute"
                    :items="minutes"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                  <span class="px-2">-</span>
                  <v-select
                    class="number-select mr-2"
                    v-model="form.hours[day].end.hour"
                    :items="hours"
                    outlined
                    dense
                    hide-details
                    style="width: 60px"
                  ></v-select>
                  <v-select
                    class="number-select"
                    v-model="form.hours[day].end.minute"
                    :items="minutes"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="changeHourStatus(day)"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        ><v-icon
                          :color="
                            !form.hours[day].active ? 'danger' : 'success'
                          "
                          >{{
                            !form.hours[day].active
                              ? "mdi-close-circle-outline"
                              : "mdi-check-circle-outline"
                          }}</v-icon
                        ></v-btn
                      >
                    </template>
                    <span v-if="!form.hours[day].active">{{
                      $t("working_time.fields.hour.enable")
                    }}</span>
                    <span v-else>{{
                      $t("working_time.fields.hour.disable")
                    }}</span>
                  </v-tooltip>
                </div>
              </div>
              <v-divider :key="'div-bottom-' + day"></v-divider>
            </template>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import { required, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Vue from "vue";

const defaultForm = {
  title: null,
  hours: {
    monday: {
      start: { hour: "08", minute: "00" },
      end: { hour: "17", minute: "00" },
      active: true,
    },
    tuesday: {
      start: { hour: "08", minute: "00" },
      end: { hour: "17", minute: "00" },
      active: true,
    },
    wednesday: {
      start: { hour: "08", minute: "00" },
      end: { hour: "17", minute: "00" },
      active: true,
    },
    thursday: {
      start: { hour: "08", minute: "00" },
      end: { hour: "17", minute: "00" },
      active: true,
    },
    friday: {
      start: { hour: "08", minute: "00" },
      end: { hour: "17", minute: "00" },
      active: true,
    },
    saturday: {
      start: { hour: "08", minute: "00" },
      end: { hour: "17", minute: "00" },
      active: false,
    },
    sunday: {
      start: { hour: "08", minute: "00" },
      end: { hour: "17", minute: "00" },
      active: false,
    },
  },
  serviceId: null,
};

export default {
  mixins: [FormDelayTouchMixin],
  created() {
    this.setHours();
    this.setMinutes();
  },
  props: {
    dialog: {
      type: Object,
      default: function () {
        return {
          display: false,
        };
      },
    },
    services: {
      type: Array,
      default: function () {
        return [];
      },
    },
    workingTime: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  validations: {
    form: {
      title: {
        required,
        maxLength: maxLength(150),
      },
      serviceId: {
        required,
      },
    },
  },
  watch: {
    "workingTime.id"(val, old) {
      if (!val) {
        if (old) {
          this.initForm();
        }
      } else {
        this.initForm();
      }
    },
  },
  data: () => ({
    form: Vue._.cloneDeep(defaultForm),
    minutes: [],
    loading: {
      save: false,
    },
  }),
  methods: {
    async saveWorkingTime() {
      if (this.$v.form.$invalid) {
        this.notify({ message: this.$t("form_error") });
        return;
      }

      let url = "working-time.create";
      const data = {
        service_id: this.form.serviceId,
        data: {
          title: this.form.title,
          hours: this.getParsedHours(this.form.hours),
        },
      };

      if (this.workingTime.id) {
        data["workingTimeId"] = this.workingTime.id;
        url = "working-time.update";
      }

      this.loading.save = true;
      try {
        const response = await this.request({
          url: url,
          data: data,
          method: "POST",
          messages: {
            200: this.$t("working_time.edit.success"),
            201: this.$t("working_time.add.success"),
            400: true,
            404: this.$t("working_time.errors.E404"),
            422: (error) => {
              if (error.code === "E100") {
                return this.$t("services.errors.404");
              } else if (error.code === "E101") {
                return this.$t("form_error");
              }
              return this.$t("error_occured");
            },
            500: true,
          },
        });
        if (!this.workingTime.id) {
          // reset form
          this.resetForm();
          // close dialog
          this.closeDialog();
          // emit refresh
          this.$emit("refresh");
        }
        this.$emit("success", response.data);
      } catch (error) {
        // empty
      }
      this.loading.save = false;
    },
    getParsedHours(hours) {
      const parsed = {};
      for (const day in hours) {
        if (!hours[day].active) continue;
        const hour = `${hours[day].start.hour}:${hours[day].start.minute}-${hours[day].end.hour}:${hours[day].end.minute}`;
        parsed[day] = hour;
      }
      return parsed;
    },
    setHours() {
      this.hours = [];
      for (let index = 1; index < 25; index++) {
        let hour = this.$utils.pad(index.toString(), 2);
        this.hours.push(hour);
      }
    },
    setMinutes() {
      this.minutes = [];
      for (let index = 0; index < 60; index++) {
        if (index % 5 !== 0) continue;
        let minute = this.$utils.pad(index.toString(), 2, true);
        this.minutes.push(minute);
      }
    },
    changeHourStatus(day) {
      this.form.hours[day].active = !this.form.hours[day].active;
    },
    closeDialog() {
      this.dialog.display = false;
    },
    initForm() {
      if (!this.workingTime.id) {
        this.resetForm();
        return;
      }
      const data = this.workingTime.data;
      const serviceId = this.workingTime.service_id
      
      this.form.serviceId = serviceId;
      this.form.title = data.title;
      this.form.hours = {};

      const defaultHours = this._.cloneDeep(defaultForm.hours);
      
      for (const day in defaultHours) {
        if (!this.$utils.hasProperty(data, `hours.${day}`)) {
          this.$set(this.form.hours, day, defaultHours[day]);
          this.form.hours[day].active = false;
        } else {
          const hour = data.hours[day].split("-");
          const start = this.$moment(hour[0], "HH:mm");
          const end = this.$moment(hour[1], "HH:mm");
          this.$set(this.form.hours, day, {
            start: { hour: start.format("HH"), minute: start.format("mm") },
            end: { hour: end.format("HH"), minute: end.format("mm") },
            active: true,
          });
        }
      }
    },
    resetForm() {
      this.$v.form.$reset();
      this.form = this._.cloneDeep(defaultForm);
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  computed: {
    titleErrors() {
      const errors = [];

      if (!this.$v.form.title.$dirty) return errors;

      !this.$v.form.title.required &&
        errors.push(this.$t("common.fields.title.required"));
      !this.$v.form.title.maxLength &&
        errors.push(
          this.$t("common.fields.title.maxLength", {
            max: this.$v.form.title.$params.maxLength.max,
          })
        );

      return errors;
    },
    serviceIdErrors() {
      const errors = [];

      if (!this.$v.form.serviceId.$dirty) return errors;

      !this.$v.form.serviceId.required &&
        errors.push(this.$t("common.fields.serviceId.required"));

      return errors;
    },
  },
};
</script>