<template>
  <div>
    <working-time-list></working-time-list>
  </div>
</template>


<script>
import WorkingTimeList from './../../components/working-time/list';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('working_time.title'),
    };
  },
  components: {
    WorkingTimeList
  }
}
</script>